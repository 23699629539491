import React, { useState, useMemo, useRef } from "react";
import { Button, Popup, Icon, Progress, Message, Divider, Loader, Placeholder } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import moment from "moment";
import styled from "styled-components";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";

import { UserChip, IdeaBusinessProfileChip } from "components/lib/Chips";

import { IdeaAssessmentReport, IdeaLikeHandler } from "components/lib/Ideas";
import Emoji from "components/lib/Emoji/Emoji";
import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";
import SelectableDropdown from "components/lib/SelectableDropdown";

import {
  SelectIdeaChip,
  IdeaListCard,
  IdeaCoverImage,
  IdeaInfo,
  StatusLabel,
  IdeaMeta,
  IdeaUpdateStatus,
  IdeaActions,
  IdeaSocialChip,
  IdeaSocialChipPlaceholder,
  AuthorPopup,
  AssessmentSummary,
  IdeaProject,
} from "./styles";

const StyledCell = styled.td`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 100%;
    min-width: 100%;
    background: #fff;

    ${({ $isPinned, $right, $left }) =>
      $isPinned
        ? `
      border-top: 1px solid #048C85;
      border-bottom: 1px solid #048C85;
      ${$right ? "border-right: 1px solid #048C85;" : ""}
      ${$left ? "border-left: 1px solid #048C85;" : ""}
    `
        : ""}
    width: 100%;
    height: 55px;

    ${({ $left }) =>
      $left
        ? `
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    `
        : ""}

    ${({ $right }) =>
      $right
        ? `
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `
        : ""}
  }
`;

const StyledProgress = styled(Progress)`
  &&&& {
    > div {
      height: 0.25rem;
    }
  }
`;

const StatusIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const Cell = ({ children, containerStyle = {}, ...props }) => (
  <StyledCell {...props}>
    <div style={containerStyle}>{children}</div>
  </StyledCell>
);

const IdeaCardListView = (props) => {
  const {
    idea,
    loading,
    basicLoading,
    isSelected,
    onSelect,
    challenge: propChallenge,
    usedVotes,
    voteForIdea,
    removeAssessors,
    ideaActions,
    invitations,
    setOpenToComments,
    setPreviewOpen,
    addTag,
    unTag,
    disableManagement,
    showChallenge,
    onRemoveExternalInvitation,
    fetchIdeas,
    hideActions,
    assessmentReportStyle,
    hasPinnedIdea,
    // Update project lane
    allChallengeProjectLanes,
    updateProjectLane,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const ideaOrder = useAppSelector((state) => state.challenges.ideaOrder);

  const { t } = useTranslation();

  const { collaborators = [], impacts = [] } = idea;
  const challenge = propChallenge || idea.ownerChallenge;
  const coverImageEnabled = !props?.challenge?.preventIdeaCoverImages;
  const canManageChallenge = util.canManageChallenge(user, challenge) && !disableManagement;
  const canActionIdeas = util.canActionIdeas(user, challenge);

  const rowRef = useRef(null);

  const hasAssessments = useMemo(
    () => idea.assessments?.length > 0 || idea?.invitedExternalAssessors?.length > 0,
    [idea],
  );
  const userAssessment = useMemo(() => {
    const hasAssessment = (idea.assessments || []).find((assessment) => assessment.user === user._id);
    return { isAssessor: !!hasAssessment, isComplete: !hasAssessment || hasAssessment?.isSubmitted };
  }, [idea, user]);
  const assessmentHasConflictOfInterest = useMemo(
    () =>
      idea.assessmentHasConflictOfInterest &&
      (canManageChallenge || util.hasPermission(user, "challenge.viewAssessments", challenge?._id)),
    [idea.assessmentHasConflictOfInterest, canManageChallenge, challenge._id, user],
  );

  const submittedAssessments = idea.assessments?.filter((a) => a.isSubmitted).length;

  const completenessPercentage = (idea.completeness || 0) * 100;
  const ideaComplete = idea.completeness && (idea.completeness || 1);
  const showCompleteness = completenessPercentage && !idea.isSubmitted;

  const { projectManagement = {} } = idea;
  const { boards = [] } = projectManagement;
  const pmBoardObject = boards.length ? boards.find((b) => b.forId === propChallenge?._id) : null;
  const userIsAssigned = useMemo(() => {
    const isAssigned = (pmBoardObject?.assignees || []).find((assigneeId) => assigneeId === user._id);
    return !!isAssigned;
  }, [user, pmBoardObject]);

  const isFullSize = !theme.sizes.isMobile;

  const [tagsOpen, setTagsOpen] = useState(false);
  const openProps = tagsOpen ? { open: tagsOpen } : {};

  const pendingInvitations = invitations && invitations.filter((i) => i?.forIdeas && i.forIdeas.includes(idea._id));

  const activeImpactSort = useMemo(
    () => !!propChallenge && idea?.impacts?.find((impact) => `impact+${impact.impactId}` === ideaOrder),
    [ideaOrder, idea, propChallenge],
  );

  const annotations = useMemo(() => {
    const notices = [];
    const problems = { ...(idea.problems ?? {}) };
    if (userAssessment?.isAssessor && !userAssessment?.isComplete) {
      notices.push(`You are an assessor for this ${t("generic.idea")} and still need to assess it.`);
    }
    if (userIsAssigned) {
      notices.push(
        `You are assigned to this ${t("generic.idea")} on the project board, make sure to update it with relevant changes.`,
      );
    }
    if (assessmentHasConflictOfInterest) {
      problems["hasConflictOfInterest"] = true;
    }
    const noticeCount = notices.length;
    const problemCount = Object.keys(problems).length;

    return {
      notices,
      problems,
      count: noticeCount + problemCount,
      hasNotices: noticeCount > 0,
      hasProblems: problemCount > 0,
    };
  }, [userAssessment, userIsAssigned, assessmentHasConflictOfInterest, idea.problems, t]);

  // Calculate the width of the progress bar
  let progressWidth = rowRef.current?.clientWidth || 0;
  if (isFullSize && (canManageChallenge || canActionIdeas)) {
    progressWidth -= 40; // adjust for checkbox (35px width + 5px padding)
  }
  if (hasPinnedIdea || canManageChallenge || canActionIdeas) {
    progressWidth -= 35; // adjust for pin icon (35px)
  }
  progressWidth -= 1; // Adjust for 1px border
  progressWidth = Math.max(progressWidth, 0);

  return (
    <>
      <IdeaListCard ref={rowRef}>
        {isFullSize && (canManageChallenge || canActionIdeas) ? (
          <>
            <td style={{ paddingRight: 5 }}>
              <SelectIdeaChip $isSelected={isSelected} onClick={() => onSelect(idea)}>
                <Icon name="check" />
              </SelectIdeaChip>
            </td>
          </>
        ) : null}

        <Cell $left $isPinned={idea.isPinned}>
          <StatusIconContainer>
            {idea.isPinned ? <Icon name="pin" className="pastel" circular inverted color="green" size="tiny" /> : null}
            {idea.submissionDeadline ? (
              <Popup
                offset={[-14, 0]}
                trigger={
                  <Icon
                    circular
                    inverted
                    name="calendar"
                    className="pastel"
                    color={moment(idea.submissionDeadline.deadline).isBefore() ? "red" : "yellow"}
                    size="tiny"
                  />
                }
                content={`This ${t("generic.idea")} ${moment(idea.submissionDeadline.deadline).isBefore() ? "had" : "has"} a custom deadline: ${moment(idea.submissionDeadline.deadline).format("DD/MM/YYYY HH:mm")}`}
              />
            ) : null}
            {!idea.currentUserHasViewed ? (
              <Icon className="pastel" circular inverted name="circle" color="blue" size="tiny" />
            ) : null}
          </StatusIconContainer>
        </Cell>
        {coverImageEnabled && (
          <Cell containerStyle={{ position: "relative" }} $isPinned={idea.isPinned}>
            {showCompleteness ? (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  minWidth: progressWidth,
                  maxHeight: 3,
                }}
              >
                <Popup
                  content={`${t("common:capitalise", { key: "generic.idea" })} is ${Math.round(completenessPercentage)}% complete`}
                  position="top center"
                  context={rowRef}
                  trigger={
                    <StyledProgress
                      size="tiny"
                      indicating={!ideaComplete}
                      success={ideaComplete}
                      percent={completenessPercentage}
                    />
                  }
                />
              </div>
            ) : null}
            {loading && !basicLoading ? (
              <Placeholder style={{ width: 40, height: 40, borderRadius: 5 }}>
                <Placeholder.Image square style={{ borderRadius: 5 }} />
              </Placeholder>
            ) : (
              <IdeaCoverImage
                $coverImage={util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())}
                onClick={() => setPreviewOpen(idea._id)}
              />
            )}
          </Cell>
        )}
        <Cell containerStyle={{ position: !coverImageEnabled && "relative" }} $isPinned={idea.isPinned}>
          {showCompleteness && !coverImageEnabled ? (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                minWidth: progressWidth,
                maxHeight: 3,
              }}
            >
              <Popup
                content={`${t("common:capitalise", { key: "generic.idea" })} is ${Math.round(completenessPercentage)}% complete`}
                position="top center"
                context={rowRef}
                trigger={
                  <StyledProgress
                    size="tiny"
                    indicating={!ideaComplete}
                    success={ideaComplete}
                    percent={completenessPercentage}
                  />
                }
              />
            </div>
          ) : null}
          <IdeaInfo $canManage={canManageChallenge}>
            <div>
              <h4
                onClick={() => setPreviewOpen(idea._id)}
                style={{
                  marginBottom: 0,
                  fontWeight: idea.currentUserHasViewed ? "400" : "700",
                }}
              >
                {idea.children ? <Icon name="sitemap" size="small" /> : null}
                {idea.name}
              </h4>
              {idea.summarisedContent ? (
                <Popup
                  trigger={<Icon name="info circle" style={{ cursor: "pointer", marginLeft: 5, height: 22 }} />}
                  content={
                    <div>
                      <p>
                        <b>AI Content summary</b>
                      </p>
                      <p>{idea.summarisedContent}</p>
                    </div>
                  }
                  on="hover"
                />
              ) : null}
            </div>
            <div>
              {idea?.owner && (
                <span className="idea-authors">
                  {idea.lockedOwnerIdeaBusinessProfile ? (
                    <IdeaBusinessProfileChip ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile} compact="very" />
                  ) : (
                    <UserChip user={idea.owner} compact="very" />
                  )}
                  {(collaborators.length || !!idea.lockedOwnerIdeaBusinessProfile) && idea.authors && isFullSize ? (
                    <>
                      &nbsp;+&nbsp;
                      <Popup
                        on="click"
                        trigger={
                          <span className="idea-others" onClick={(e) => e.preventDefault()}>
                            {util.pluralise(
                              idea.lockedOwnerIdeaBusinessProfile?.profile?.name
                                ? collaborators.length + 1
                                : collaborators.length,
                              "other",
                              "others",
                            )}
                          </span>
                        }
                        content={
                          <AuthorPopup>
                            {idea?.authors
                              .filter((a) => !!idea.lockedOwnerIdeaBusinessProfile || a._id !== idea.user)
                              .map((a) => (
                                <UserChip key={a._id} user={a} />
                              ))}
                          </AuthorPopup>
                        }
                      />
                    </>
                  ) : null}
                </span>
              )}
            </div>
          </IdeaInfo>
          <div
            style={{
              width: "1px",
              borderRight: "0.5px solid gray",
              height: "40px",
            }}
          />
        </Cell>
        {showChallenge && isFullSize && challenge ? (
          <Cell>
            <IdeaUpdateStatus style={{ width: "100%", paddingLeft: 5 }}>
              <Icon name="target" />
              {challenge.name ? (
                <Link to={`/challenges/${challenge._id}`}>{challenge.name}</Link>
              ) : (
                <span>{t("common:capitalise", { key: "generic.challenge" })} deleted</span>
              )}
            </IdeaUpdateStatus>
          </Cell>
        ) : null}
        {isFullSize && (
          <Cell $right={hideActions} $isPinned={idea.isPinned}>
            <IdeaMeta>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Popup
                  disabled={!idea.stamps?.length || idea.stamps?.length <= 5}
                  trigger={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {idea.stamps?.length > 0 && isFullSize
                        ? idea.stamps.slice(0, 5).map((stamp) => <Emoji key={stamp} emoji={{ id: stamp }} size={15} />)
                        : null}
                      {idea.stamps?.length > 5 ? (
                        <div
                          style={{
                            fontSize: 10,
                            lineHeight: "10px",
                          }}
                        >
                          <span>+{idea.stamps.length - 5} others</span>
                        </div>
                      ) : null}
                    </div>
                  }
                  content={
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      {idea.stamps?.length > 0 && isFullSize
                        ? idea.stamps.map((stamp) => <Emoji key={stamp} emoji={{ id: stamp }} size={15} />)
                        : null}
                    </div>
                  }
                />
              </div>
              <IdeaUpdateStatus>
                {idea.isSubmitted ? (
                  <>
                    <StatusLabel color="green" />
                    <span>Submitted {moment(idea.submittedAt).format("DD/MM/YY")}</span>
                  </>
                ) : (
                  <>
                    <StatusLabel color="yellow" />
                    <span>Updated {moment(idea.updatedAt).format("DD/MM/YY")}</span>
                  </>
                )}
                {allChallengeProjectLanes?.length > 0 && !loading ? (
                  <Popup
                    style={{ minWidth: 400 }}
                    hoverable
                    on="click"
                    trigger={
                      <IdeaProject $isAssigned={userIsAssigned}>
                        <span>
                          <Icon name="compass" onClick={() => navigate(`/challenges/${challenge?._id}/board`)} />
                        </span>
                        <span className="lane-name">
                          {pmBoardObject?.laneObject?.name ? `Lane: ${pmBoardObject.laneObject.name}` : "Not on board"}
                        </span>
                      </IdeaProject>
                    }
                    content={
                      <div>
                        <h5 style={{ margin: 0 }}>Project Management</h5>
                        <span style={{ marginBottom: 10, display: "block" }}>
                          {pmBoardObject?.laneObject?.name
                            ? `This ${t("generic.idea")} is added to the ${t("generic.challenge")}'s project board on the '${pmBoardObject.laneObject.name}' lane.`
                            : `This ${t("generic.idea")} is not assigned to ${t("generic.challengeWithArticle")} project board lane.`}
                          {" You can change this in the dropdown below."}
                        </span>
                        <SelectableDropdown
                          single
                          placeholder={`Add this ${t("generic.idea")} to a project board lane`}
                          value={pmBoardObject?.lane}
                          clearable={false}
                          options={[
                            {
                              text: propChallenge?.projectBoard?.defaultLane?.name ?? "Default",
                              value: "default",
                            },
                            ...allChallengeProjectLanes.map((lane) => ({
                              text: lane.name,
                              value: lane._id,
                            })),
                          ]}
                          onChange={(value) => updateProjectLane(idea._id, value)}
                        />
                        {userIsAssigned ? (
                          <Message>
                            You are assigned to this {t("generic.idea")}, make sure to update it with relevant changes
                            on the project board.
                          </Message>
                        ) : null}
                      </div>
                    }
                  />
                ) : null}
              </IdeaUpdateStatus>

              <Popup
                style={{ zIndex: 990, ...(assessmentReportStyle || {}) }}
                disabled={!hasAssessments}
                trigger={
                  hasAssessments ? (
                    <div>
                      {loading && !basicLoading ? (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Loader active inline size="mini" />
                          <span style={{ fontSize: "0.9em", margin: "0 0 0 5px" }}>Assessments loading..</span>
                        </div>
                      ) : (
                        <AssessmentSummary $isComplete={userAssessment?.isComplete}>
                          {!userAssessment?.isComplete ? (
                            <span>
                              <Icon name="wait" />
                            </span>
                          ) : null}
                          <span>
                            {Math.round(idea.assessmentSubmittedScore || 0)}% from {submittedAssessments || 0}/
                            {idea.assessments?.length || 0} assessors
                          </span>
                          {pendingInvitations?.length > 0 ? <span> ({pendingInvitations?.length} pending)</span> : null}
                        </AssessmentSummary>
                      )}
                    </div>
                  ) : null
                }
                on="click"
                basic
                content={
                  <div style={{ width: 500 }}>
                    <IdeaAssessmentReport
                      fetchIdeas={fetchIdeas}
                      challenge={challenge}
                      idea={idea}
                      removeAssessors={removeAssessors}
                      onRemoveExternalInvitation={onRemoveExternalInvitation}
                    />
                    {!userAssessment?.isComplete ? (
                      <Message>
                        You are assigned as an assessor for this {t("generic.idea")} and still need to assess it.
                      </Message>
                    ) : null}
                  </div>
                }
              />
            </IdeaMeta>
          </Cell>
        )}

        {!hideActions ? (
          <Cell $right $isPinned={idea.isPinned}>
            <IdeaActions>
              {annotations.count > 0 && canManageChallenge ? (
                loading ? (
                  <IdeaSocialChipPlaceholder />
                ) : (
                  <Popup
                    trigger={
                      <IdeaSocialChip $actioned $warning={annotations.hasProblems}>
                        <Icon name={annotations.hasProblems ? "warning sign" : "info circle"} />
                        <span>{annotations.count}</span>
                      </IdeaSocialChip>
                    }
                    content={
                      <div>
                        {annotations.problems?.usersNotInAudience ? (
                          <div>
                            <h5>
                              This {t("generic.ideas")} has assignees or assessors that aren't currently in the{" "}
                              {t("generic.challenge")} audience:
                            </h5>
                            {annotations.problems.usersNotInAudience.assessors?.length ? (
                              <div style={{ marginBottom: 10 }}>
                                The following assessors are not currently in the audience:{" "}
                                <b>
                                  {idea.problems.usersNotInAudience.assessorUsers
                                    .map((assessor) => assessor.profile.fullName)
                                    .join(", ")}
                                </b>
                              </div>
                            ) : null}

                            {annotations.problems.usersNotInAudience.assignees?.length ? (
                              <div>
                                The following project assignees are not currently in the audience:{" "}
                                <b>
                                  {idea.problems.usersNotInAudience.assigneeUsers
                                    .map((assignee) => assignee.profile.fullName)
                                    .join(", ")}
                                </b>
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {annotations.problems.hasConflictOfInterest ? (
                          <h5>An assessor in this {t("generic.idea")} has declared a conflict of interest.</h5>
                        ) : null}

                        {annotations.notices.length > 0 && !!annotations.problems ? <Divider /> : null}

                        {annotations.notices.length > 0 ? (
                          <div>
                            <h5>You should consider:</h5>
                            <ol style={{ paddingLeft: 20 }}>
                              {annotations.notices.map((notice) => (
                                <li key={notice}>{notice}</li>
                              ))}
                            </ol>
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                )
              ) : null}

              {activeImpactSort && isFullSize ? (
                <IdeaSocialChip $backgroundColor="#E03997" $fitContent>
                  {activeImpactSort?.ownerImpact ? (
                    <Icon name={activeImpactSort?.ownerImpact?.icon} style={{ marginLeft: 1 }} />
                  ) : null}
                  <span>{activeImpactSort.annualisedValue ?? activeImpactSort.value}</span>
                </IdeaSocialChip>
              ) : null}

              {isFullSize && challenge?.tagList?.length > 0 ? (
                loading ? (
                  <IdeaSocialChipPlaceholder />
                ) : (
                  <Popup
                    on="hover"
                    hoverable
                    style={{ zIndex: 1000 }}
                    trigger={
                      <IdeaSocialChip>
                        <Icon name="hashtag" />
                        <span>{idea.tags?.length || 0}</span>
                      </IdeaSocialChip>
                    }
                    content={
                      <div>
                        <h4>Tags</h4>
                        <TagModalChooser
                          trigger={
                            <Button compact icon="hashtag" content="Edit tags" size="tiny" style={{ marginLeft: 3 }} />
                          }
                          tags={idea.fullTags}
                          availableTags={challenge.tagList.filter((tag) => !tag?.disabledEntities?.includes("ideas"))}
                          addTag={addTag}
                          removeTag={unTag}
                          canEdit={canManageChallenge}
                          onStateChange={(state) => setTagsOpen(state)}
                        />
                        {impacts?.length > 0 && (
                          <>
                            <h4>Impact</h4>
                            <IdeaActions justify="center">
                              {impacts
                                .filter((i) => i?.ownerImpact && i?.value && i.ownerImpact?.type !== "dropdown")
                                .map((impact) => (
                                  <IdeaSocialChip key={impact.value} $actionable={false}>
                                    <Icon name={impact.ownerImpact.icon} />
                                    <span>{impact.value}</span>
                                  </IdeaSocialChip>
                                ))}
                            </IdeaActions>
                            <div style={{ width: "100%", height: "100%" }}>
                              {impacts
                                .filter((i) => i?.ownerImpact && i?.value && i.ownerImpact?.type === "dropdown")
                                .map((impact) => (
                                  <div key={impact.value}>
                                    <Icon name={impact.ownerImpact.icon} />
                                    <span
                                      style={{
                                        fontSize: "1em",
                                        fontWeight: "bold",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                        color: "#505050",
                                      }}
                                    >
                                      {impact.value}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </>
                        )}
                      </div>
                    }
                    {...openProps}
                  />
                )
              ) : null}

              {!challenge?.preventIdeaComments ? (
                loading ? (
                  <IdeaSocialChipPlaceholder />
                ) : (
                  <IdeaSocialChip
                    onClick={() => {
                      setOpenToComments(true);
                      setPreviewOpen(idea._id);
                    }}
                  >
                    <Icon name="conversation" />
                    <span>{idea.commentCount || 0}</span>
                  </IdeaSocialChip>
                )
              ) : null}

              {challenge?.votingVisibility === "users" ? (
                loading ? (
                  <IdeaSocialChipPlaceholder />
                ) : (
                  <IdeaLikeHandler
                    idea={idea}
                    challenge={challenge}
                    onLikeIdea={voteForIdea}
                    trigger={
                      <IdeaSocialChip $actionable={!usedVotes && idea.votedFor} $actioned={idea.votedFor}>
                        <Icon name="thumbs up" />
                        {canManageChallenge ||
                        util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ||
                        challenge?.voteCountVisibility === "users" ? (
                          <span>{idea.voteCount || 0}</span>
                        ) : null}
                      </IdeaSocialChip>
                    }
                  />
                )
              ) : null}

              {canManageChallenge || canActionIdeas ? (
                loading ? (
                  <IdeaSocialChipPlaceholder />
                ) : (
                  <Popup
                    on="click"
                    trigger={
                      <IdeaSocialChip $iconOnly>
                        <Icon name="settings" />
                      </IdeaSocialChip>
                    }
                    style={{ padding: 5 }}
                    content={ideaActions([idea._id])}
                    position="top right"
                  />
                )
              ) : null}
            </IdeaActions>
          </Cell>
        ) : null}
      </IdeaListCard>
      <tr height="4" />
    </>
  );
};

export default IdeaCardListView;
