/* eslint-disable no-restricted-syntax */
import api from "api";
import ConfirmModal from "components/lib/Modal/ConfirmModal";
import PromptModal from "components/lib/Modal/PromptModal";
import AlertModal from "components/lib/Modal/AlertModal";
import VideoModal from "components/lib/Video/VideoModal";
import { createConfirmation } from "components/lib/Modal/MountPoint";
import { t } from "i18next";
import toast from "react-hot-toast";
import { store } from "store";
import actions from "actions";
import { SearchParams } from "simplydo/core";

import constants from "./constants";

// const store = getStore ? getStore() : null;

// Some global permissions are unscoped, meaning they are not tied to a specific organisation or challenge
// For example, viewing the superadmin dashboard supersedes the idea of an organisation, therefore the scope is irrelevant
// However, things like ghosting INTO an organisation are scoped, because you can only ghost into an organisation you have access to
// There are also some middle grounds, handled on a per-function basis. For example, listing recent users will only list users in organisations you have access to, but listing all users is a global permission
const UNSCOPED_GLOBAL_PERMISSIONS = [
  "super.viewDashboard",
  "super.listUsers",
  "super.createOrgs",
  "super.managePermissions",
  "super.listRecents",
  "super.listOrgs",
];

const util = {
  isProduction() {
    return import.meta.env.VITE_IS_PRODUCTION === "true";
  },
  appName() {
    return "SimplyDo";
  },
  code() {
    const params = new SearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      return code;
    }
    const url = window.location.hostname.toLowerCase();
    if (url.indexOf("app.simplydo.co.uk") > -1) null;
    if (url.indexOf("simplydo.co.uk") > -1) return url.split(".")[0];
    if (url.indexOf("localhost") > -1) return "simplydo";
    return null;
  },
  host(user) {
    return `https://${user?.ownerOrganisation?.code || "app"}.simplydo.co.uk`;
  },
  hasPermission(user, permission, scope) {
    if (!user || !user.permissions || !permission) return false;
    // See comments above for how unscope permissions work
    if (UNSCOPED_GLOBAL_PERMISSIONS.indexOf(permission) > -1) {
      for (const [scopeKey, permissions] of Object.entries(user.permissions)) {
        if (scopeKey.includes("global") && scopeKey !== "global" && permissions.indexOf(permission) > -1) {
          return true;
        }
      }
    }
    if (user.permissions.global) {
      for (let i = 0; i < user.permissions.global.length; i += 1) {
        if (permission === user.permissions.global[i]) return true;
      }
    }
    if (scope) {
      const relevantRoles = [...(user.permissions[scope] || []), ...(user.permissions[`global.${scope}`] || [])];
      for (let i = 0; i < relevantRoles.length; i += 1) {
        if (permission === relevantRoles[i]) return true;
      }
    }
    return false;
  },
  hasAgreedTerms(user) {
    if (!user || !user.legalAgreements || !user.legalAgreements.privacyPolicy || !user.legalAgreements.termsOfUse)
      return false;
    const agreePrivacy =
      user.legalAgreements.privacyPolicy.filter((a) => a.version === constants.termsVersion).length > 0;
    const agreeTerms = user.legalAgreements.termsOfUse.filter((a) => a.version === constants.termsVersion).length > 0;
    return !!(agreePrivacy && agreeTerms);
  },
  hasVerifiedEmail(user, organisation) {
    if (!user) return false;
    if (!organisation || !organisation.emailValidationEnforcing) return true;
    if (!user.emails || !user.emails.length) return false;
    return user.emails[0].verified;
  },
  canCreateChallenges(user) {
    const org = user && user.ownerOrganisation;
    if (org && org.enabledFeatures && org.enabledFeatures.indexOf("challenges") > -1) {
      if (util.hasPermission(user, "org.createChallenges", user.ownerOrganisation._id)) {
        return true;
      }
    }
    return false;
  },
  canManageChallenge(user, challenge) {
    return user && challenge && util.hasPermission(user, "org.manageChallenges", challenge.organisation);
  },
  canActionIdeas(user, challenge) {
    if (!challenge || !user) {
      return false;
    }
    return (
      util.hasPermission(user, "challenge.moveProjectIdeas", challenge?._id) ||
      util.hasPermission(user, "challenge.editProjectAssignees", challenge?._id) ||
      util.hasPermission(user, "challenge.editProjectDates", challenge?._id) ||
      util.hasPermission(user, "challenge.editProjectAssignees", challenge?._id) ||
      util.hasPermission(user, "challenge.editProjectDates", challenge?._id) ||
      util.hasPermission(user, "challenge.pinIdeas", challenge?._id) ||
      util.hasPermission(user, "challenge.editIdeaDeadlines", challenge?._id) ||
      util.hasPermission(user, "challenge.editIdeaLabels", challenge?._id) ||
      util.hasPermission(user, "challenge.mergeIdeas", challenge?._id) ||
      util.hasPermission(user, "challenge.editAssessments", challenge?._id) ||
      util.hasPermission(user, "challenge.moveIdeasToChallenge", challenge?._id) ||
      util.hasPermission(user, "org.addToGroupsDirectly", user?.organisation?._id) ||
      util.hasPermission(user, "org.managePermissions", user.organisation._id)
    );
  },
  canManageGroups(user, organisation) {
    return (
      user &&
      organisation &&
      (util.hasPermission(user, "org.manageGroups", organisation) || util.hasPermission(user, "super.viewDashboard"))
    );
  },
  canCreateIdea(user, challenge) {
    // if public idea creation is enabled, anyone can create and idea
    // if public idea creation is disabled, only users in the challenges audience can create an idea
    if (challenge && challenge?.visibility?.publicIdeaCreation) return true;
    if (user && challenge)
      return (
        challenge?.visibility?.organisations?.includes(user?.ownerOrganisation?._id) ||
        user?.availableOrganisations?.some((x) => challenge?.visibility?.organisations?.includes(x._id)) ||
        user?.groups?.some((g) => challenge?.visibility?.groups?.includes(g._id))
      );
    return false;
  },
  isInGroup(user, group) {
    return group && user && user.groups && user.groups.map((g) => g._id).indexOf(group._id) > -1;
  },
  canEditIdea(user, idea, needsAuth = false) {
    if (!idea) return false;
    const canEdit =
      user &&
      idea &&
      (idea.user === user._id ||
        (idea.collaborators || []).indexOf(user._id) > -1 ||
        (idea.authors || []).find((author) => author._id === user._id));
    if (!canEdit && idea.authToken && !needsAuth) {
      let authToken = null;
      if (this.localStorageIsSupported()) {
        authToken = localStorage.getItem(`ideaAuthToken:${idea._id}`);
      }
      return authToken && idea.authToken === authToken;
    }
    return canEdit;
  },
  canJoinIdeaChat(user, idea) {
    if (!user || !idea) return false;
    const canEdit = this.canEditIdea(user, idea);
    const { collaborators = [] } = idea;
    return canEdit && collaborators.length;
  },
  orgFavicon(org) {
    return org?.faviconUrl || "https://cdn.simplydo.co.uk/images/icon.png";
  },
  profileLogo(profile) {
    const logoUrl = profile && profile.logoUrl;
    const logo = profile && profile.logo;
    return logoUrl || logo || util.getCdnFile("images/defaults/profile-logo.png");
  },
  avatarUrl(user, size = "100x100") {
    const avatar = user && user.profile && user.profile.avatar;
    if (avatar && avatar.match(/^[0-9]+\.png/)) {
      return util.getCdnFile(`images/avatars/${avatar}`);
    }
    return util.getThumbnail(user?.profile, "avatar", size, "images/avatars/default.png");
  },
  ideaCoverImage(idea, size = "200x200") {
    return util.getThumbnail(idea, "coverImage", size, "images/idea.png");
  },
  challengeImage(challenge) {
    return util.getImageUrl(
      challenge,
      ["coverImage", "bannerImage"],
      ["640x100", "300x300"],
      "images/challengeBanner.jpg",
    );
  },
  groupCoverImage(group, size = "200x200") {
    return util.getThumbnail(group, "coverImage", size, "images/groupCoverImage.png");
  },
  groupBannerImage(group, size = "200x200") {
    return util.getThumbnail(group, "bannerImage", size, "images/groupBanner.jpg");
  },
  mixinCssUrlFallback(origUrl, ...fallbackUrls) {
    let mixinUrl = `${origUrl}), linear-gradient(white, white`;
    fallbackUrls.forEach((url) => {
      mixinUrl = `${mixinUrl}), url(${url}`;
    });
    return mixinUrl;
  },
  challengeLink(challenge) {
    if (!challenge) return "";
    const link = `/challenges/${challenge._id}`;
    return link;
  },
  getCdnFile(path) {
    return `https://cdn.simplydo.co.uk/${path}`;
  },
  getFileIcon(file) {
    let i = "file";
    let ext = file.split(".").pop();
    if (ext) {
      ext = ext.toLowerCase();
      if (ext === "pdf") i += " pdf";
      if (ext === "csv" || ext === "xls" || ext === "xlsx") i += " excel";
      if (ext === "doc" || ext === "docx") i += " word";
      if (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "gif") i += " image";
      if (ext === "ppt" || ext === "pptx") i += " powerpoint";
      if (ext === "zip" || ext === "tar" || ext === "gx") i += " archive";
      if (ext === "mp4" || ext === "avi" || ext === "mov") i += " video";
      if (ext === "py" || ext === "js" || ext === "md" || ext === "java" || ext === "html" || ext === "css")
        i += " code";
    }
    return `${i} outline`;
  },
  pluralise(count, s1, s2, includeNumber = true) {
    return `${includeNumber ? `${count || 0} ` : ""}${count === 1 ? s1 : s2}`;
  },
  ensureHttp(s) {
    if (!s) return;
    if (s.indexOf("http") === -1) return `http://${s}`;
    return s;
  },
  activePath(pattern, location) {
    const match = (location || window.location).pathname.match(new RegExp(pattern, "i"));
    return match && match.length > 0;
  },
  confirm(title, content) {
    const confirm = createConfirmation(ConfirmModal);
    return confirm({ title, confirmation: content });
  },
  prompt(title, question, onComplete) {
    const prompt = createConfirmation(PromptModal);
    return prompt({ title, prompt: question, onComplete });
  },
  alert(title, content) {
    const alert = createConfirmation(AlertModal);
    return alert({ title, confirmation: content });
  },
  video(title, videoUrl) {
    const modal = createConfirmation(VideoModal);
    return modal({ title, confirmation: videoUrl });
  },
  loginWithSaml(code) {
    let then = "/";
    const params = new SearchParams(window.location.search);

    // Check if we are already handling a redirection
    const thenParam = params.get("then");
    if (thenParam) {
      params.delete("then");
      then = thenParam;
    } else {
      then = window.location.pathname;
    }

    const finalParams = new SearchParams();
    // Append remaining search params (excluding "then", which was popped earlier)
    then += params.toSafeString();
    finalParams.set("next_path", then);

    let url;
    if (util.isProduction()) {
      url = `https://${code}.simplydo.co.uk${import.meta.env.VITE_SAML_ENDPOINT}`;
    } else {
      finalParams.set("code", code);
      url = import.meta.env.VITE_SAML_ENDPOINT;
    }

    window.location.href = url + finalParams.toSafeString();
  },
  logoutWithSaml(nameId, code) {
    if (nameId) {
      if (util.isProduction()) {
        window.location = `https://${code}.simplydo.co.uk${import.meta.env.VITE_SAML_LOGOUT}?name_id=${nameId}`;
      } else {
        window.location = `${import.meta.env.VITE_SAML_LOGOUT}?name_id=${nameId}`;
      }
    }
  },
  loginWithOAuth(service, code) {
    let then = "/";
    const params = new SearchParams(window.location.search);

    // Check if we are already handling a redirection
    const thenParam = params.get("then");
    if (thenParam) {
      params.delete("then");
      then = thenParam;
    } else {
      then = window.location.pathname;
    }

    // Append remaining search params (excluding "then", which was popped earlier)
    then += params.toSafeString();

    window.location = `${import.meta.env.VITE_LOGIN_SERVICE}/auth/oauth/${service}/login?code=${code}${then && then !== "/" ? `&next_path=${encodeURIComponent(then)}` : ""}`;
  },
  connectOAuth(service, user, type, typeId, contextId, forRole) {
    window.location = `${import.meta.env.VITE_LOGIN_SERVICE}/auth/oauth/${service}/connect?user=${user._id}&${type}=${typeId}&contextId=${contextId}${forRole ? `&forRole=${forRole}` : ""}&returnTo=${encodeURIComponent(window.location)}`;
  },
  userIsConnectedTo(user, service) {
    return user && (user.enabledServices || []).indexOf(service) > -1;
  },
  adjustColour(input, lum) {
    let hex = String(input).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    const adjustment = lum || 0;

    let rgb = "#";
    let c;
    for (let i = 0; i < 3; i += 1) {
      if (typeof hex === "string") {
        c = parseInt(hex.substring(i * 2, i * 2 + 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * adjustment), 255)).toString(16);
        rgb += `00${c}`.substring(c.length);
      }
    }
    return rgb;
  },
  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  },
  hexToRgbString(hex, opacity) {
    const rgb = util.hexToRgb(hex);
    return opacity ? `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})` : `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  },
  handleNewIdea(challenge, user, navigateFunction) {
    const { _id, name } = challenge;
    api.challenges.createIdea(
      _id,
      `${t("common:capitalise", { key: "generic.idea" })} for ${name || "Undefined"}`,
      (idea) => {
        if (user) store.dispatch(actions.user.addIdeaId(idea._id));
        if (!user) {
          let challengeIdeas = "";
          if (this.localStorageIsSupported()) {
            localStorage.setItem(`ideaAuthToken:${idea._id}`, idea.authToken);
            challengeIdeas = localStorage.getItem(`challengeIdeas:${challenge._id}`) || "";
          }
          if (!challengeIdeas.includes(idea._id)) {
            if (challengeIdeas !== "") {
              challengeIdeas += "::";
            }
            challengeIdeas += `${idea._id}`;
            localStorage.setItem(`challengeIdeas:${challenge._id}`, challengeIdeas);
          }
        }
        navigateFunction(idea);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  },
  newIdea(challenge, user, navigateFunction) {
    if (challenge?.stage !== "published" && this.canManageChallenge(user, challenge)) {
      this.confirm(
        `Closed ${t("generic.challenge")}`,
        `You're attempting to make ${t("generic.ideaWithArticle")} for a closed ${t("generic.challenge")}. Only challenge admins can do this; You will need to open your ${t("generic.challenge")} before other users can make ideas`,
      )
        .then(() => this.handleNewIdea(challenge, user, navigateFunction))
        .catch(() => {});
    } else this.handleNewIdea(challenge, user, navigateFunction);
  },
  getProfileName(owner) {
    return owner?.name || (owner && owner.profile?.fullName) || "Someone";
  },
  getProfileJob(owner) {
    const { profile = {} } = owner;
    const { jobTitle, department } = profile;
    return `${jobTitle || ""}${jobTitle && department ? " - " : ""}${department || ""}`;
  },
  getName(user) {
    if (!user) {
      return "Someone";
    }
    // This should cover the case for anything that is not a user
    if (user.name) {
      return user.name;
    }
    if (!user.profile) {
      return "Someone";
    }
    if (!user.profile.fullName) {
      return "Someone";
    }
    return user.profile.fullName;
  },
  getEventNames(events, ownerPath = "ownerUser") {
    const firstEventOwner = events[0]?.[ownerPath];
    const secondEventOwner =
      events
        .map((e) => e[ownerPath] || {})
        .find((ownerUser) => ownerUser._id && ownerUser._id !== firstEventOwner?._id) || {};
    const uniqueEntityIds = [];
    for (let i = 0; i < events.length; i += 1) {
      const event = events[i];
      const eventOwnerEntity = event[ownerPath];
      if (
        eventOwnerEntity &&
        uniqueEntityIds.indexOf(eventOwnerEntity._id) === -1 &&
        eventOwnerEntity._id !== secondEventOwner._id &&
        eventOwnerEntity._id !== firstEventOwner._id
      ) {
        uniqueEntityIds.push(eventOwnerEntity._id);
      }
    }
    let initialString = util.getName(firstEventOwner);
    if (!util.isEmpty(secondEventOwner)) {
      if (uniqueEntityIds.length > 0) initialString += ", ";
      else initialString += " and ";
      initialString += util.getName(secondEventOwner);
    }
    if (uniqueEntityIds.length > 0) {
      initialString += ` and ${util.pluralise(uniqueEntityIds.length, "other", "others")}`;
    }
    return initialString;
  },
  isEmpty(obj) {
    if (!obj) {
      return true;
    }
    if (Array.isArray(obj)) {
      return obj.length === 0;
    }
    if (typeof obj === "string") {
      return obj === "";
    }
    return Object.keys(obj).length === 0;
  },
  deepishClone(data) {
    if (data && typeof data === "object") {
      if (Array.isArray(data)) {
        data = [...data];
        data = data.map((d) => {
          if (typeof d === "object" && !d.length) {
            return { ...d };
          }
          return d;
        });
      } else {
        data = { ...data };
      }
    }
    return data;
  },
  shouldShowInterestsChooser(user) {
    if (!user) return false;
    const options = user.ownerOrganisation?.tagList.map((t) => t._id);
    if (options?.length === 0 || !options) {
      return false;
    }
    let canChoose = false;
    let chosen = [];
    canChoose = user.ownerOrganisation?.onboarding?.enforceTagSelection;
    chosen = user.tags || [];

    return canChoose && chosen.filter((tag) => options.includes(tag)).length === 0;
  },
  getChatName(user, thread) {
    const { name, participantUsers = [] } = thread;
    if (name) return name;
    const filteredUsers = participantUsers.filter((p) => p._id !== user._id);
    if (filteredUsers.length === 0) return "Unknown user";
    if (filteredUsers.length === 1) return filteredUsers[0].profile.fullName;
    let basicName = `You, ${filteredUsers
      .slice(0, 2)
      .map((u) => u.profile.fullName)
      .join(", ")}`;
    if (filteredUsers.length > 2) {
      const otherUsers = filteredUsers.length - 2;
      basicName += ` + ${otherUsers} others`;
    }
    return basicName;
  },
  getChatAvatar(user, thread) {
    const { participantUsers = [] } = thread;
    const filteredUsers = participantUsers?.filter((p) => p._id !== user._id);
    if (filteredUsers.length === 1) return util.avatarUrl(filteredUsers[0]);
    return null;
  },
  // https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  },
  shouldShowIdeaSection(idea, section) {
    if (!idea || !section) return false;
    return section.type === "assessment"
      ? idea.ideaTemplate.assessmentEnabled && idea.isAssessor
      : section.fields?.length > 0 || section.type === "businessProfile";
  },
  shouldShowIdeaEdiSection(user, idea, section) {
    if (!idea || !section) return false;
    if (util.canEditIdea(user, idea)) return true;
    if (section.type === "edi" && !util.hasPermission(user, "org.viewEdi", idea.ownerChallenge?.organisation))
      return false;
    return true;
  },
  browserIsSupported() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIE = userAgent.indexOf("trident") > -1;
    const isNonChromiumEdge = userAgent.indexOf("edge") > -1;
    return !isIE && !isNonChromiumEdge;
  },
  validateEmail(email) {
    // This is not all encompassing
    // Do not expect this to completely validate every possible email address
    // It's just for simple front end validation, so people dont enter their name in the email field
    return /^\S+@\S+$/.test(email);
  },
  validateUrl(value) {
    // https://stackoverflow.com/a/15855457
    return /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i.test(value);
  },
  getUrlPath(url) {
    if (!this.validateUrl(url)) return undefined;
    const hostMatch = url.match(/(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/);
    return hostMatch[6] || "";
  },
  /*
    An object in the following away will look like the following
    {
      type: "challenge",
      _id: ObjectId(),
      createdAt: Date(),
      following: true,
      unfollowed: false
    }
    The `unfollowed` key will be set to true (and following set to false) if the user manually unfollows the item. We use this to check if
    we should automatically re-follow the item once they interact with it again. For example, if a user unfollows a challenge, commenting
    on the discussion won't refollow
  */
  getUserFollowing(user, context) {
    // Provide a context (e.g. challenge) and get which ones the user is following
    const existingFollowing = user?.following || [];
    const existingFollowingContext = existingFollowing?.filter((f) => f.type === context);
    return existingFollowingContext;
  },
  userIsFollowingContext(user, context, id) {
    // Get the following for a given context (e.g. challenge) and see if they follow that challenge by passing an id
    const existingFollowingContext = this.getUserFollowing(user, context);
    const followingContent = existingFollowingContext.find((f) => f._id === id);
    return followingContent?.following;
  },
  userIsFollowingContextObject(user, context, id) {
    // Same as above
    // But returns the object, not just if they are following or not
    // Can be used to check date when they followed or if they unfollowed manually
    const existingFollowingContext = this.getUserFollowing(user, context);
    const followingContent = existingFollowingContext.find((f) => f._id === id);
    return { ...(followingContent || { type: context, _id: id, following: false }) };
  },
  parseJwt(token) {
    if (!token) return;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(""),
    );
    return JSON.parse(jsonPayload);
  },
  moveArrayItem(arr, from, to) {
    const newArr = [...arr];
    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);
    return newArr;
  },
  limitNumberInput(e, success, fail = () => {}) {
    if (!e.target.value) success("");
    const numericalInput = parseInt(e.target.value, 10);
    if (!Number.isNaN(numericalInput)) {
      success(numericalInput);
    } else fail(e.target.value);
  },
  reverseString(str) {
    if (str === "") return "";
    return this.reverseString(str.substr(1)) + str.charAt(0);
  },
  splitRevenueString(revenue) {
    const reversedRevenueString = this.reverseString(revenue || "");
    let splitString = "";
    [...reversedRevenueString].forEach((char, index) => {
      if (index !== 0 && index % 3 === 0) splitString += ",";
      splitString += char;
    });
    return this.reverseString(splitString);
  },
  urlSearchParamsToObject(entries) {
    if (!Array.isArray(entries)) return entries;
    const result = entries.reduce((p, [key, value]) => {
      p[key] = value;
      return p;
    }, {});
    return result;
  },
  arraysHaveSameValues(arr1, arr2) {
    return arr1.every((val) => arr2.includes(val)) && arr2.every((val) => arr1.includes(val));
  },
  organisationFeaturesEnabled(user, requiredFeatures) {
    const enabledFeatures = user?.ownerOrganisation?.enabledFeatures || [];
    return requiredFeatures.every((feature) => enabledFeatures.indexOf(feature) > -1);
  },
  localStorageIsSupported() {
    if (this.isLocalStorageSupported !== undefined) {
      return this.isLocalStorageSupported;
    }
    try {
      const key = "test_key_simplydo_local_storage";
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  },
  getImageUrl(entity, fields, sizes, defaultUrl) {
    if (entity && fields) {
      for (const field of fields) {
        if (sizes) {
          if (entity[field]) {
            if (entity[field].startsWith("unsplash-")) {
              const [w, h] = sizes[0].split("x");
              return entity[field].slice(9) + `&fit=min&w=${w}&h=${h}`;
            }
          }
          const thumbnailField = `${field}ThumbnailUrls`;
          if (entity[thumbnailField]) {
            for (const size of sizes) {
              if (entity[thumbnailField][size]) {
                return entity[thumbnailField][size];
              }
            }
          }
        }
        const regularField = `${field}Url`;
        if (entity[regularField]) {
          return entity[regularField];
        }
      }
    }
    return defaultUrl ? util.getCdnFile(defaultUrl) : null;
  },
  getThumbnail(entity, field, size, defaultUrl) {
    return util.getImageUrl(entity, [field], size ? [size] : undefined, defaultUrl);
  },
  convertToDayOrWeek(hours) {
    if (!hours) {
      return null;
    }
    const isNegative = hours < 0 ? "-" : "";
    hours = Math.abs(hours);
    if (hours >= 24) {
      const days = Math.floor(hours / 24);
      if (days >= 7) {
        const weeks = Math.floor(days / 7);
        let result = `${isNegative}${weeks} week${weeks === 1 ? "" : "s"}`;

        const remainingDays = days % 7;
        if (remainingDays > 0) {
          result += `, ${remainingDays} day${remainingDays === 1 ? "" : "s"}`;
        }

        return result;
      }

      let result = `${isNegative}${days} day${days === 1 ? "" : "s"}`;
      const remainingHours = hours % 24;
      if (remainingHours > 0) {
        result += `, ${remainingHours} hour${remainingHours === 1 ? "" : "s"}`;
      }
      return result;
    }
    return `${isNegative}${hours} hour${hours === 1 ? "" : "s"}`;
  },
  tryConvertStringToInt(string) {
    const number = parseInt(string, 10);
    if (Number.isNaN(number)) return string;
    return number;
  },
  ideaMeetsChallengeVisibilityLimits(idea, challenge) {
    // A challenge may have visibility limits when it allows users to view ideas
    // For example, this is an array that could be ['pinned', 'submitted']
    // In this instance, a user can only view ideas that are pinned or submitted
    // If there are no visibility limits, then the user can view all ideas, as long as the challenge has an open idea list
    const { ideaVisibilityLimits = [] } = challenge;
    if (challenge.ideaVisibility !== "users") {
      return false;
    }
    if (challenge.ideaVisibility === "users" && ideaVisibilityLimits.length === 0) {
      return true;
    }
    if (ideaVisibilityLimits.includes("pinned") && idea.isPinned) {
      return true;
    }
    if (ideaVisibilityLimits.includes("submitted") && idea.isSubmitted) {
      return true;
    }
    return false;
  },
  variablesAreEqual(var1, var2) {
    if (typeof var1 !== typeof var2) {
      return false;
    }

    if (var1 === null || var2 === null) {
      return var1 === var2;
    }

    if (Array.isArray(var1)) {
      if (!Array.isArray(var2)) {
        return false;
      }

      if (var1.length !== var2.length) {
        return false;
      }

      for (let i = 0; i < var1.length; i += 1) {
        if (!util.variablesAreEqual(var1[i], var2[i])) {
          return false;
        }
      }

      return true;
    }

    if (typeof var1 === "object") {
      const var1Keys = Object.keys(var1);
      const var2Keys = Object.keys(var2);

      if (var1Keys.length !== var2Keys.length) {
        return false;
      }

      for (const key of var1Keys) {
        if (!util.variablesAreEqual(var1[key], var2[key])) {
          return false;
        }
      }

      return true;
    }

    return var1 === var2;
  },
  countDifferentKeys(dict1, dict2, ignoredKeys = []) {
    let count = 0;

    for (const key in dict2) {
      if (dict2.hasOwnProperty(key) && !ignoredKeys.includes(key)) {
        if (!dict1.hasOwnProperty(key) || !this.variablesAreEqual(dict1[key], dict2[key])) {
          count++;
        }
      }
    }

    return count;
  },
  isUUID(str) {
    // Regular expression to match UUID format
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(str);
  },
  union(...arrays) {
    // Use a Set to store unique values
    const uniqueValues = new Set();

    // Iterate through each array and add its elements to the Set
    arrays.forEach((array) => {
      array.forEach((element) => {
        uniqueValues.add(element);
      });
    });

    // Convert the Set back to an array and return the result
    return Array.from(uniqueValues);
  },
  downloadCompanyPdf(company) {
    return new Promise((resolve, reject) => {
      api.auth.requestWebdriverToken(
        `/companies?company=${company._id}`,
        ({ token }) => {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", `${import.meta.env.VITE_WEBDRIVER}/companies?company=${company._id}`);
          xhr.setRequestHeader("Authorization", `Bearer ${token}`);
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                if (data && data.url) {
                  const element = document.createElement("a");
                  element.setAttribute("href", data.url);
                  element.setAttribute("download", `${company.name.replace(/ /g, "_")}.pdf`);
                  element.setAttribute("target", "_blank");
                  element.style.display = "none";
                  document.body.appendChild(element);
                  element.click();
                  document.body.removeChild(element);
                  resolve(t("ideas.pdf.download"));
                } else {
                  reject(t("ideas.pdf.error"));
                }
              } else {
                reject(t("ideas.pdf.error"));
              }
            }
          };
          xhr.send();
        },
        () => {
          reject("Could not generate PDF");
        },
      );
    });
  },
  downloadMultiIdeaPdf(ideaIds) {
    return new Promise((resolve, reject) => {
      api.reports.exportIdeaPdfs(
        ideaIds,
        {},
        (result) => {
          const element = document.createElement("a");
          element.setAttribute("href", result.url);
          element.setAttribute("download", `ideas.pdf`);
          element.setAttribute("target", "_blank");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          resolve(t("ideas.pdf.download"));
        },
        () => reject(t("ideas.pdf.error")),
      );
    });
  },
  canAccessSuperadminOrgList(user) {
    if (!user) {
      return false;
    }
    const userPermissions = user.permissions ?? {};
    for (const [scopeKey, permissions] of Object.entries(userPermissions)) {
      if (scopeKey.includes("global") && permissions.includes("super.listOrgs")) {
        return true;
      }
    }
    return false;
  },
  assessmentIsSubmissable(assessment, ideaTemplate, assessmentClosed) {
    return (
      !assessment?.isSubmitted &&
      !assessmentClosed &&
      ((!assessment.conflictOfInterest?.hasConflictOfInterest &&
        ideaTemplate.body
          ?.flatMap((section) => (section.type === "assessment" && section.fields) || [])
          .filter((field) => field.type === "assessment")
          .every((field) => {
            const score = assessment.assessment[field.id]?.score;
            // @ts-ignore
            return score !== undefined && score !== "" && score !== null;
          })) ||
        (assessment.conflictOfInterest?.hasConflictOfInterest && !!assessment.conflictOfInterest.notes))
    );
  },
};

util.isLocalStorageSupported = util.localStorageIsSupported();

export default util;
