import React, { useContext } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Divider } from "semantic-ui-react";
import { PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import { ThemeContext } from "styled-components";
import util from "utils/utils";
import actions from "actions";
import { SideNav } from "components/lib/SideNav";
import IdeaTags from "./Tags";
import IdeaTeam, { RequestIdeaTeam } from "./Team";
import useIdeaScrollTracking from "./useIdeaScrollTracking";

const IdeaSideBar = ({ user, idea, loading, isIdeaPage, isEditing, sectionRefs, t, updateIdea, leaveIdea, main }) => {
  const theme = useContext(ThemeContext);
  const { activeItemId, scrollToSection } = useIdeaScrollTracking(idea._id, sectionRefs);

  const { ideaTemplate = {} } = idea;
  const { body = [] } = ideaTemplate;

  const canEdit = util.canEditIdea(user, idea);
  const canManageChallenge = util.canManageChallenge(user, idea.ownerChallenge);

  return (
    <SideNav
      top={isEditing ? 155 : 105}
      navItems={
        !theme.sizes.isComputer
          ? undefined
          : [
              loading
                ? {
                    key: "loading",
                    type: "custom",
                    content: (
                      <PlaceholderStack gap={15}>
                        <PlaceholderItem height={40} />
                        <PlaceholderItem height={40} />
                        <PlaceholderItem height={40} />
                      </PlaceholderStack>
                    ),
                  }
                : null,
              ...body.map((section, idx) => {
                const sectionName = section.name ?? "";
                if (util.shouldShowIdeaSection(idea, section)) {
                  return {
                    key: `${sectionName}-${idx}`,
                    content: sectionName.charAt(0).toUpperCase() + sectionName.slice(1),
                    iconLeft: "arrow alternate circle right",
                    active: !!(isIdeaPage && activeItemId === section.id),
                    onClick: () => scrollToSection(section.id),
                  };
                }
                return null;
              }),
              idea?.ownerChallenge?.impactStatements?.enabled &&
              idea?.ownerChallenge?.impactStatements?.showOnIdea &&
              (canManageChallenge ||
                canEdit ||
                util.hasPermission(user, "challenge.viewProjectImpacts", idea.challenge))
                ? {
                    key: "impactStatement",
                    content: "Impact Statement",
                    iconLeft: "balance scale",
                    active: !!(isIdeaPage && activeItemId === "impactStatement"),
                    onClick: () => scrollToSection("impactStatement"),
                  }
                : null,
              (isEditing || idea.attachments?.length > 0) &&
              !idea?.ideaTemplate?.disableAttachments &&
              !theme.sizes.isMobile
                ? {
                    key: "attachments",
                    content: t("generic.attachments"),
                    iconLeft: "paperclip",
                    active: !!(isIdeaPage && activeItemId === "attachments"),
                    onClick: () => scrollToSection("attachments"),
                  }
                : null,
              (!isEditing || idea.commentCount >= 1) &&
              !idea.ownerChallenge?.preventIdeaComments &&
              !theme.sizes.isMobile &&
              !loading
                ? {
                    key: "comments",
                    content: t("generic.comments"),
                    iconLeft: "discussions",
                    active: !!(isIdeaPage && activeItemId === "comments"),
                    onClick: () => scrollToSection("comments"),
                  }
                : null,
              user && !idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge)
                ? {
                    key: "team",
                    type: "custom",
                    content: (
                      <>
                        <Divider />
                        <div style={{ padding: "0 12px" }}>
                          <IdeaTeam
                            updateIdea={updateIdea}
                            idea={idea}
                            isSidebar
                            canEdit={canEdit}
                            leaveIdea={leaveIdea}
                          />
                        </div>
                      </>
                    ),
                  }
                : null,
              user && !util.canEditIdea(user, idea) && !idea?.ownerChallenge?.preventCollaboration && !loading
                ? {
                    key: "teamJoinRequest",
                    type: "custom",
                    content: (
                      <>
                        <Divider
                          fitted
                          hidden={!idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge)}
                        />
                        <RequestIdeaTeam idea={idea} updateIdea={updateIdea} />
                      </>
                    ),
                  }
                : null,
              user && isEditing && idea?.ownerChallenge?.tagList?.length > 0
                ? {
                    key: "tags",
                    type: "custom",
                    content: (
                      <>
                        <Divider />
                        <div style={{ padding: "0 12px" }}>
                          <h5 style={{ color: "#333", marginBottom: 10 }}>{t("ideas.tags.title")}</h5>
                          <IdeaTags updateIdea={updateIdea} idea={idea} isSidebar />
                        </div>
                      </>
                    ),
                  }
                : null,
            ].filter((item) => Boolean(item))
      }
      nav={
        !theme.sizes.isComputer &&
        isEditing && (
          <div style={{ padding: "0px", marginBottom: 10 }}>
            <IdeaTags updateIdea={updateIdea} idea={idea} isSidebar />
            {user && !idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge) && (
              <IdeaTeam updateIdea={updateIdea} idea={idea} isSidebar canEdit={canEdit} leaveIdea={leaveIdea} />
            )}
          </div>
        )
      }
      main={main}
    />
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data) => dispatch(actions.challenges.updateIdeaFilters(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(IdeaSideBar));
